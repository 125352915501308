import { types } from "mobx-state-tree";
import { z } from "zod";
import { areHintsEnabledDefaultValue } from "./constants";

// IMPORTANT:
// This needs to be manually kept in
// sync with styled.d.ts exported interface
// for Default Theme.

const Theme = z.object({
  tileSize: z.number().optional(),
  colors: z
    .object({
      text: z.string(),
      textSecondary: z.string(),
      background: z.string(),
      positive: z.string(),
      clue: z.string(),
      coin: z.object({
        dark: z.string(),
        light: z.string(),
      }),
      perfectScoreHeader: z.string(),
      modal: z.object({
        background: z.string(),
        text: z.string(),
      }),
      reviewBar: z.object({
        background: z.string(),
        text: z.string(),
      }),
      tile: z.object({
        hidden: z.string(),
        hiddenHovered: z.string(),
        revealed: z.string(),
        revealedHovered: z.string(),
      }),
      mainMenu: z.object({
        playButtonText: z.string(),
        playButtonBg: z.string(),
        playButtonDisabled: z.string(),
        helpButtonText: z.string(),
        helpButtonBg: z.string(),
      }),
      numbers: z.object({
        1: z.string(),
        2: z.string(),
        3: z.string(),
        4: z.string(),
        5: z.string(),
        6: z.string(),
        7: z.string(),
        8: z.string(),
      }),
      settings: z.object({
        background: z.string(),
        toggleBackground: z.string(),
        toggleText: z.string(),
        toggleElement: z.string(),
        text: z.string(),
      }),
      shareModal: z.object({
        letsGoButtonBg: z.string(),
      }),
      onboardingModal: z.object({
        background: z.string(),
        text: z.string(),
        textStrong: z.string(),
        primaryButton: z.string(),
        radioActive: z.string(),
        radioNotActive: z.string(),
      }),
    })
    .optional(),
  animations: z
    .object({
      tileReveal: z.string(),
    })
    .optional(),
  fonts: z
    .object({
      baseSizePercentage: z.number(),
      baseStack: z.string(),
      boldStack: z.string(),
      serifStack: z.string(),
      monoStack: z.string(),
      settings: z.object({
        baseSize: z.number(),
      }),
    })
    .optional(),
});

/**
 * WARNING:
 *
 * Needs to be manually kept in sync with the mobx state tree
 * GameplaySettingsModel in Settings.ts
 *
 * Settings need to be manually read in app.finishLoading() if
 * they're special. Otherwise, they need to be explicitly handled
 * in Settings.loadConfigGameplaySettings().
 *
 * 1. Add to the settings model
 * 2. Add default value
 * 3. Add setter to appropriate place
 */
const GameplaySettings = z.object({
  treasureReveal: z.boolean().default(true),
  bombPenalty: z.number().default(5),
  scoring: z.enum(["time", "clicks"]).default("clicks"),
  hintsOptionDefault: z.boolean().default(areHintsEnabledDefaultValue),
  showHintsOptionInSettingsMenu: z.boolean().default(false),
  pars: z
    .object({
      mon: z.number().default(9),
      tue: z.number().default(9),
      wed: z.number().default(12),
      thu: z.number().default(25),
      fri: z.number().default(25),
      sat: z.number().default(32),
      sun: z.number().default(32),
    })
    .default({}),
  showScoreMedallion: z.boolean().default(true),
  showPlayWithFriendsModal: z.boolean().default(true),
});

export type ConfigGameplaySettings = z.infer<typeof GameplaySettings>;

const Text = z.object({
  gameUrl: z
    .string()
    .default("https://www.puzzlesociety.com/member/logic-puzzles/caved"),
  letsGoUrl: z
    .string()
    .default("https://www.puzzlesociety.com/member/friends"),
  moreGamesUrl: z
    .string()
    .default("https://www.puzzlesociety.com/member/all-games"),
  gameName: z.string().default("Caved"),
  playButton: z.string().default("Play"),
  topBarResetButton: z.string().default("Reset"),
  topBarResultsButton: z.string().default("Results"),
  by: z.string().default("By"),
  failedToLoadLevel: z.string().default("Failed to load level"),
  pauseModalBody: z.string().default("Game Paused"),
  pauseModalButton: z.string().default("Resume"),
  resetModalTitle: z.string().default("REPLAY?"),
  resetModalBody: z
    .string()
    .default(
      "Do you want to reset the board and play again?"
    ),
  resetModalReplayButton: z.string().default("Replay"),
  resetModalBackButton: z.string().default("Go Back"),
  shareClipboardTotalMoves: z.string().default("Total Moves"),
  shareClipboardSkullsHit: z.string().default("Skulls Hit"),
  shareModalBackButton: z.string().default("Back"),
  shareModalTitle: z.string().default("Score copied to clipboard"),
  shareModalBody: z.string().default("Share it in a text, tweet, or post."),
  shareModalPlayWithFriendsTitle: z.string().default("Play with friends"),
  shareModalPlayWithFriendsBody: z
    .string()
    .default(
      "Did you know that you can add friends to The Puzzle Society and see their scores each day?"
    ),
  shareModalPlayWithFriendsButton: z.string().default("Let's go"),
  statsModalPerfectScoreTitle: z.string().default("Ultimate Score"),
  statsModalPerfectScoreBody: z
    .string()
    .default("Your score today is among the Society’s best!"),
  statsModalNormalScoreTitle: z.string().default("Congratulations!"),
  statsModalNormalScoreBody: z.string().default("You solved today's Caved"),
  societyPointsPerfectScore: z.string().default("400"),
  societyPointsNormalScore: z.string().default("200"),
  statsModalScoreLabel: z.string().default("Clicks"),
  statsModalRevealedBombCountLabel: z.string().default("Skulls Uncovered:"),
  statsModalTimeLabel: z.string().default("Time:"),
  statsModalCluesRevealedLabel: z.string().default("Clues Revealed:"),
  statsModalShareWithAFriend: z.string().default("Share with a friend"),
  statsModalMoreGames: z.string().default("More games"),
  onboardingModalWelcome: z.string().default("Welcome to"),
  settingsMenuMuteLabel: z.string().default("Mute"),
  settingsMenuUseHintsLabel: z.string().default("Use Hints"),
  settingsMenuShowTutorialLabel: z.string().default("Show Tutorial"),
});

const Html = z.object({
  coinParagraph: z.string().default("Society<br/>Points"),
  onboardingModalSlide1Paragraph1: z
    .string()
    .default(
      "In this game, you'll be clicking <strong>blank tiles</strong> to reveal what's hidden underneath."
    ),
  onboardingModalSlide1Paragraph2: z
    .string()
    .default(
      "Your goal is to uncover all the <strong>gems</strong> while avoiding <strong>skulls</strong>, in the fewest clicks possible!"
    ),
  onboardingModalSlide2Body: z
    .string()
    .default(
      "<p><strong>Item clues</strong> tell you how many items (gems AND/OR skulls) they touch in any direction (including diagonally).</p><p>In this example, the highlighted <strong>1 clue</strong> is touching one item.</p><p>There are no gems or skulls in the seven uncovered squares, so we know there must be one under <strong>the upper-right diagonal tile</strong>!</p>"
    ),
  onboardingModalSlide3Body: z
    .string()
    .default(
      "<p> <strong>TIP!</strong> </p> <p> You can <strong>flag</strong> a tile by <strong>long-clicking</strong>. </p> <p> This is a way to keep track of which tiles have items without accidentally uncovering a skull. </p> <p>You don't have to flag items to win, but it's a helpful tool!</p>"
    ),
  onboardingModalSlide4Body: z
    .string()
    .default(
      "<p> <strong>Oh, look!</strong> </p> <p> This <strong>1 clue</strong> is already touching an item! That means there can't be any skulls lurking in the three tiles above it. </p> <p> You're safe to dig: Let's click on those tiles to reveal more clues! </p>"
    ),
  onboardingModalSlide5Body: z
    .string()
    .default(
      "<p> <strong>Treasure clues</strong> point to gems. </p> <p> In this example, the treasure clue is telling us that <strong>one gem</strong> is hidden in the <strong>top row to its left</strong>. </p>"
    ),
  onboardingModalSlide6Body: z
    .string()
    .default(
      "<p> <strong>You're ready to play!</strong> Get out there and dig! </p> <p>Can you find all the gems in as few clicks as possible?</p>"
    ),
});

const Config = z.object({
  appVersion: z.string().optional(),
  colors: z.record(z.string(), z.string()).optional(),
  themes: z
    .object({
      defaultTheme: Theme.deepPartial().optional(),
      mainMenuTheme: Theme.deepPartial().optional(),
    })
    .optional(),
  gameplaySettings: GameplaySettings.default({}),
  text: Text.default({}),
  html: Html.default({}),
});

type ConfigSchema = z.infer<typeof Config>;

// Interop type for zod and mst schemas
export const MstConfigSchema = types.custom<ConfigSchema, ConfigSchema>({
  name: "ConfigSchema",
  fromSnapshot(value: ConfigSchema): ConfigSchema {
    return Config.parse(value);
  },
  toSnapshot(value: ConfigSchema) {
    return value;
  },
  isTargetType(value: string | ConfigSchema): boolean {
    return Config.safeParse(value).success;
  },
  getValidationMessage(value: ConfigSchema): string {
    const result = Config.safeParse(value);

    if (result.success) {
      return "";
    } else {
      return result.error.message;
    }
  },
});

function parseConfigFile(fileContents: any) {
  console.log("Trying to parse Config file: ", { fileContents });

  const config: ConfigSchema = Config.parse(fileContents);

  console.log("Parsed Config File: ", { config });

  return config;
}

export default parseConfigFile;
export type { ConfigSchema };
