{
  "name": "cluefinder",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "version": "1.26.0",
  "description": "",
  "dependencies": {
    "@parcel/resolver-glob": "^2.8.3",
    "common-tags": "^1.8.2",
    "compare-versions": "^6.1.0",
    "date-fns": "^2.30.0",
    "howler": "^2.2.4",
    "lodash.merge": "^4.6.2",
    "mobx": "^6.7.0",
    "mobx-react-lite": "^3.4.0",
    "mobx-state-tree": "^5.1.7",
    "normalize.css": "^8.0.1",
    "parcel": "^2.11.0",
    "process": "^0.11.10",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-inlinesvg": "^4.1.1",
    "seedrandom": "^3.0.5",
    "styled-components": "^5.3.6",
    "zod": "^3.22.4"
  },
  "scripts": {
    "start": "parcel src/index.html -p 3000",
    "build": "parcel build src/index.html",
    "fakeAmuServer": "http-server tests/data --cors",
    "generateConfigFile": "bun scripts/generateConfigFile.ts"
  },
  "author": "rmkubik",
  "license": "UNLICENSED",
  "devDependencies": {
    "@types/bun": "^1.0.8",
    "@types/common-tags": "^1.8.1",
    "@types/howler": "^2.2.11",
    "@types/json-stable-stringify": "^1.0.36",
    "@types/lodash.merge": "^4.6.7",
    "@types/ramda": "^0.29.11",
    "@types/react": "^18.0.26",
    "@types/react-dom": "^18.0.9",
    "@types/seedrandom": "^3.0.4",
    "@types/styled-components": "^5.1.26",
    "bun": "^1.0.30",
    "chalk": "^5.3.0",
    "http-server": "^14.1.1",
    "json-stable-stringify": "^1.1.1",
    "ramda": "^0.29.1"
  },
  "alias": {
    "react-inlinesvg/provider": "react-inlinesvg/dist/provider.js"
  }
}
